import { ItemCategory } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * 商品カテゴリに関する機能を提供します.
 */
class ItemCategoryService {
  /**
   * 初期状態の ItemCategory
   */
  public get defaultItemCategory(): ItemCategory {
    return {
      guid: "",
      slugName: "",
      name: "",
      aliasName: "",
      description: "",
      imageUrl: "",
      displayOrder: 0,
      isAvailable: true,
      updatedAt: "",
      createdAt: "",
    } as ItemCategory;
  }

  /**
   * すべての商品カテゴリを取得して返します.
   * @returns すべての商品カテゴリ
   */
  public async allItemCategories() {
    const response = await sdk.itemCategories();
    if (response?.itemCategories) {
      return response.itemCategories as ItemCategory[];
    }
    return [] as ItemCategory[];
  }

  /**
   * トップレベルの商品カテゴリを取得して返します.
   * @returns トップレベルの商品カテゴリ
   */
  public async getToplevelCategories() {
    const response = await sdk.toplevelItemCategories();
    if (response?.toplevelItemCategories) {
      return response.toplevelItemCategories as ItemCategory[];
    }
    return [] as ItemCategory[];
  }

  /**
   * 指定したGUIDに一致する商品カテゴリを取得して返します.
   * @param guid 商品カテゴリのGUID
   * @returns 商品カテゴリ
   */
  public async getItemCategory(guid: string) {
    const response = await sdk.itemCategory({ itemCategoryGuid: guid });
    return response.itemCategory as ItemCategory;
  }

  /**
   * 商品カテゴリを登録します.
   * @param input 商品カテゴリの登録情報
   * @returns 登録完了後の商品カテゴリ
   */
  public async createItemCategory(input: ItemCategory) {
    try {
      if (!input.slugName || !input.name) {
        throw new Error("slugName and name is required.");
      }
      const response = await sdk.createItemCategory({
        createItemCategoryInput: {
          slugName: input.slugName,
          parentGuid: input.parent?.guid ? input.parent?.guid : undefined,
          name: input.name,
          aliasName: input.aliasName,
          imageUrl: input.imageUrl,
          description: input.description,
          displayOrder: input.displayOrder,
          isAvailable: input.isAvailable,
        },
      });

      if (response?.createItemCategory) {
        return response.createItemCategory;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 商品カテゴリを更新します.
   * @param input 商品カテゴリの更新情報
   * @returns 更新完了後の商品カテゴリ
   */
  public async updateItemCategory(input: ItemCategory) {
    try {
      if (!input.slugName || !input.name) {
        throw new Error("slugName and name is required.");
      }
      const response = await sdk.updateItemCategory({
        updateItemCategoryInput: {
          guid: input.guid,
          slugName: input.slugName,
          parentGuid: input.parent?.guid ? input.parent?.guid : undefined,
          name: input.name,
          aliasName: input.aliasName,
          imageUrl: input.imageUrl,
          description: input.description,
          displayOrder: input.displayOrder,
          isAvailable: input.isAvailable,
        },
      });

      if (response?.updateItemCategory) {
        return response.updateItemCategory;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 商品カテゴリを削除します.
   * @param input 商品カテゴリの削除情報
   * @returns 削除完了後の商品カテゴリ
   */
  public async deleteItemCategory(input: ItemCategory) {
    try {
      const response = await sdk.deleteItemCategory({
        deleteItemCategoryInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteItemCategory) {
        return response.deleteItemCategory;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new ItemCategoryService();

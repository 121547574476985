import { ItemBrand } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * 商品ブランドに関する機能を提供します.
 */
class ItemBrandService {
  /**
   * 初期状態の ItemBrand
   */
  public get defaultItemBrand(): ItemBrand {
    return {
      guid: "",
      slugName: "",
      name: "",
      nameKana: "",
      description: "",
      imageUrl: "",
      displayOrder: 0,
      isAvailable: true,
      updatedAt: "",
      createdAt: "",
    } as ItemBrand;
  }

  /**
   * すべての商品ブランドを取得して返します.
   * @returns すべての商品ブランド
   */
  public async allItemBrands() {
    const response = await sdk.itemBrands();
    if (response?.itemBrands) {
      return response.itemBrands as ItemBrand[];
    }
    return [] as ItemBrand[];
  }

  /**
   * 指定したGUIDに一致する商品ブランドを取得して返します.
   * @param guid 商品ブランドのGUID
   * @returns 商品ブランド
   */
  public async getItemBrand(guid: string) {
    const response = await sdk.itemBrand({ itemBrandGuid: guid });
    return response.itemBrand as ItemBrand;
  }

  /**
   * 商品ブランドを登録します.
   * @param input 商品ブランドの登録情報
   * @returns 登録完了後の商品ブランド
   */
  public async createItemBrand(input: ItemBrand) {
    try {
      const response = await sdk.createItemBrand({
        createItemBrandInput: {
          slugName: input.slugName,
          name: input.name,
          nameKana: input.nameKana,
          description: input.description,
          imageUrl: input.imageUrl,
          displayOrder: input.displayOrder,
          isAvailable: input.isAvailable,
        },
      });

      if (response?.createItemBrand) {
        return response.createItemBrand;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 商品ブランドを更新します.
   * @param input 商品ブランドの更新情報
   * @returns 更新完了後の商品ブランド
   */
  public async updateItemBrand(input: ItemBrand) {
    try {
      const response = await sdk.updateItemBrand({
        updateItemBrandInput: {
          guid: input.guid,
          slugName: input.slugName,
          name: input.name,
          nameKana: input.nameKana,
          description: input.description,
          imageUrl: input.imageUrl,
          displayOrder: input.displayOrder,
          isAvailable: input.isAvailable,
        },
      });

      if (response?.updateItemBrand) {
        return response.updateItemBrand;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 商品ブランドを削除します.
   * @param input 商品ブランドの削除情報
   * @returns 削除した商品ブランド
   */
  public async deleteItemBrand(input: ItemBrand) {
    try {
      const response = await sdk.deleteItemBrand({
        deleteItemBrandInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteItemBrand) {
        return response.deleteItemBrand;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new ItemBrandService();

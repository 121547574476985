









































import { Item } from "@/graphql/client";
import { Component, Emit, Vue } from "vue-property-decorator";
import itemService from "@/service/itemService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class ItemExportDialog extends Vue {
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: Item = itemService.defaultItem;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  /**
   * ダイアログを表示します.
   */
  public open(item: Item): void {
    this.item = item;

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  private launchImportItems(): void {
    this.isProgressing = true;
    //delete
    itemService
      .launchImportItems()
      .then((result) => {
        this.isProgressing = false;
        this.notifySuccess(result as Item[]);
      })
      .catch((err) => {
        console.error(err as Error);
        this.isProgressing = false;
        this.notifyError(err as Error);
      });
  }

  /**
   * 処理成功
   */
  @Emit("onSuccess")
  public notifySuccess(items: Item[]): Item[] {
    return items;
  }

  /**
   * 処理成功
   */
  @Emit("onError")
  public notifyError(error: Error): Error {
    return error;
  }
}

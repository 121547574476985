var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":{ itemsPerPageOptions: [20, 50, 100, -1] },"search":_vm.searchKeyword,"no-data-text":"商品は登録されていません。","loading":_vm.loadingDataGrid,"loading-text":"商品を読み込んでいます...","server-items-length":_vm.totalItems,"options":_vm.itemDataOptions},on:{"update:options":function($event){_vm.itemDataOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"名称","clearable":""},model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=$$v},expression:"searchName"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"メーカー品番","clearable":"","hide-details":""},model:{value:(_vm.searchMakerCode),callback:function ($$v) {_vm.searchMakerCode=$$v},expression:"searchMakerCode"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"カタログコード","clearable":"","hide-details":""},model:{value:(_vm.searchCatalogCode),callback:function ($$v) {_vm.searchCatalogCode=$$v},expression:"searchCatalogCode"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-magnify","label":"キーワード検索","clearable":""},model:{value:(_vm.searchKeyword),callback:function ($$v) {_vm.searchKeyword=$$v},expression:"searchKeyword"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("検索対象：名称、表示名、品番、大進品番、部門ID、メーカー品番、他社商品コード、JANCODE、WEB品番、"),_c('br'),_vm._v("キャッチコピー、説明、要約、キーワード、メモ、キャラクター（名称）、ブランド（名称）、商品カテゴリ（名称）")])])],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"primary","dark":"","depressed":""},on:{"click":_vm.openCreateDialog}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" 商品を追加 ")],1)],1)],1)],1),_c('ItemEditDialog',{ref:"editDialog",on:{"onCreateSuccess":_vm.onCreateSuccess,"onUpdateSuccess":_vm.onUpdateSuccess}}),_c('ItemEditBundlabeDialog',{ref:"editBundleableDialog",on:{"onUpdateSuccess":_vm.onUpdateBundleableSuccess}}),_c('ItemDeleteDialog',{ref:"deleteDialog",on:{"onSuccess":_vm.onDeleteSuccess}}),_c('ItemExportDialog',{ref:"exportDialog",on:{"onSuccess":_vm.onExportSuccess,"onError":_vm.onExportError}}),_c('ItemImportDialog',{ref:"importDialog",on:{"onSuccess":_vm.onImportSuccess,"onError":_vm.onImportError}})]},proxy:true},_vm._l((_vm.headers.filter(function (header) { return header.hasOwnProperty('formatter'); }
    )),function(header){return {key:("item." + (header.value)),fn:function(ref){
    var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.editBundleable",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.openEditBundleableDialog(item)}}},[_vm._v(" mdi-relation-one-to-many ")])]}},{key:"item.editAction",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.openEditDialog(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.deleteAction",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_vm._v(" mdi-trash-can-outline ")])]}},{key:"item.price",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(_vm._s(_vm._f("formatPrice")(item.price)))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }











import DefaultPageTemplate from "@/components/templates/DefaultPageTemplate.vue";
import ItemList from "@/components/organisms/item/ItemList.vue";

export default {
  components: {
    DefaultPageTemplate,
    ItemList,
  },
};

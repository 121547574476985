










import { Component, Vue } from "vue-property-decorator";
import Notification from "@/components/molecules/Notification.vue";
import { NotificationState } from "@/store/modules/notification";

@Component({
  components: {
    Notification,
  },
})
export default class DefaultPageTemplate extends Vue {
  //---------------------------
  // computed
  //---------------------------
  get type(): string {
    return NotificationState.type;
  }
  get message(): string {
    return NotificationState.message;
  }
  get visibility(): boolean {
    return NotificationState.visibility;
  }
}







































































































































import { Item, Option, ItemCategory, ItemBrand } from "@/graphql/client";
import { Purpose } from "@/graphql/client";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import itemService from "@/service/itemService";
import itemCategoryService from "@/service/itemCategoryService";
import purposeService from "@/service/purposeService";
import optionService from "@/service/optionService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import itemBrandService from "@/service/itemBrandService";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import typeService from "@/service/typeService";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class ItemEditBundlabeDialog extends Mixins(MixinFormatter) {
  @Ref() readonly itemForm!: HTMLFormElement;
  @Validations()
  validations = {};
  //---------------------------
  // data
  //---------------------------
  purposes = [] as Purpose[];
  options: Option[] = [];
  itemCategories: ItemCategory[] = [];
  itemBrands: ItemBrand[] = [];
  taxTypes = typeService.taxTypes;
  isOpen = false;
  isProgressing = false;
  item: Item = itemService.defaultItem;
  itemsToBundle: Item[] = [];
  headers = [
    {
      text: "商品コード",
      value: "code",
      width: "10%",
    },
    {
      text: "大進品番",
      value: "daishinCode",
      width: "10%",
    },
    {
      text: "名称",
      value: "name",
    },
  ];
  searchKeyword = "";
  loadingDataGrid = false;

  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  get formattedIsAvailable(): string {
    return this.getIsAvailableLabel(
      this.item.isAvailable ? this.item.isAvailable : false
    );
  }
  //---------------------------
  // methods
  //---------------------------
  private async fetchData(item: Item): Promise<void> {
    this.loadingDataGrid = true;

    //用途一覧取得
    this.purposes = await purposeService.allPurposes();
    //オプション一覧取得
    this.options = await optionService.allOptions();

    //商品カテゴリ取得
    this.itemCategories = await itemCategoryService.allItemCategories();

    //商品ブランド取得
    this.itemBrands = await itemBrandService.allItemBrands();

    //商品一覧取得
    let itemsToBundle = await itemService.allItems();

    //表示データ取得
    this.item = itemService.defaultItem;
    if (item.guid) {
      itemService.getItem(item.guid).then((item) => {
        if (item) {
          this.item = item;
          itemsToBundle = itemsToBundle.filter((elm) => {
            //自身は除く
            return elm.guid !== this.item.guid;
          });
          this.itemsToBundle = itemsToBundle;
          this.loadingDataGrid = false;
        }
      });
    }
  }
  /**
   * ダイアログを表示します.
   */
  public open(item: Item): void {
    this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  save(): void {
    console.log(this.item.bundledItems);
    this.isProgressing = true;

    itemService
      .updateBundledItems(this.item)
      .then((result) => {
        this.isProgressing = false;
        this.notifyUpdateSuccess(result as Item);
      })
      .catch((err) => {
        console.error(err);
        this.notifyError("バンドル商品を登録できませんでした.");
      });
  }

  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: Item): Item {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}

import { Catalog, UpsertCatalogInput } from "@/graphql/client";
import stringify from "csv-stringify";
import iconv from "iconv-lite";
import { sdk } from "@/plugins/graphql-provider";

/**
 * カタログマスタに関する機能を提供します.
 */
class CatalogService {
  /**
   * 初期状態の Catalog
   */
  public get defaultCatalog(): Catalog {
    return {
      guid: "",
      name: "",
      code: "",
      createdAt: "",
      updatedAt: "",
    } as Catalog;
  }

  /**
   * すべてのカタログを取得して返します.
   * @returns すべてのカタログ
   */
  public async allCatalogs() {
    const response = await sdk.catalogs();
    if (response?.catalogs) {
      return response.catalogs as Catalog[];
    }
    return [] as Catalog[];
  }

  /**
   * 指定したGUIDに一致するカタログを取得して返します.
   * @param guid カタログのGUID
   * @returns カタログ
   */
  public async getCatalog(guid: string) {
    const response = await sdk.catalog({ catalogGuid: guid });
    return response.catalog as Catalog;
  }

  /**
   * 指定したコードに一致するカタログを取得して返します.
   * @param code カタログのコード
   * @returns カタログ
   */
  public async getCatalogByCode(code: string) {
    const response = await sdk.catalogByCode({ catalogByCodeCode: code });
    return response.catalogByCode as Catalog;
  }

  /**
   * すでに登録済みのカタログコードの場合は true を返します.
   * @param code コード
   * @returns true
   */
  public async isExistCatalogCode(code: string) {
    const catalog = await this.getCatalogByCode(code);
    return catalog != undefined;
  }

  /**
   * カタログを登録します.
   * @param input カタログの登録情報
   * @returns 登録完了後のカタログ
   */
  public async createCatalog(input: Catalog) {
    try {
      const response = await sdk.createCatalog({
        createCatalogInput: {
          name: input.name,
          code: input.code,
        },
      });

      if (response?.createCatalog) {
        return response.createCatalog;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * カタログを更新します.
   * @param input カタログの更新情報
   * @returns 更新完了後のカタログ
   */
  public async updateCatalog(input: Catalog) {
    try {
      const response = await sdk.updateCatalog({
        updateCatalogInput: {
          guid: input.guid,
          name: input.name,
          code: input.code,
        },
      });

      if (response?.updateCatalog) {
        return response.updateCatalog;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * カタログを削除します.
   * @param input カタログの削除情報
   * @returns 削除したカタログ
   */
  public async deleteCatalog(input: Catalog) {
    try {
      const response = await sdk.deleteCatalog({
        deleteCatalogInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteCatalog) {
        return response.deleteCatalog;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * カタログマスタの検索結果をCSVでダウンロードします.
   * @param condition 検索条件
   */
  public async exportCSV(): Promise<void> {
    const items = await this.allCatalogs();
    stringify(
      items,
      {
        columns: ["guid", "code", "name", "createdAt", "updatedAt"],
        header: true,
      },
      function (err: Error | undefined, csv: string): void {
        const sjisCsv = iconv.encode(csv, "Shift_JIS");
        const blob = new Blob([sjisCsv], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "カタログマスタ.csv";
        link.click();
        link.remove();
      }
    );
  }

  /**
   * カタログマスタを一括登録（および更新）します。
   * @param catalogs カタログ情報の配列
   */
  public async upsertCatalogs(catalogs: Catalog[]) {
    const upsertCatalogs: UpsertCatalogInput[] = [];
    catalogs.forEach((item) => {
      upsertCatalogs.push({
        guid: item.guid ? item.guid : undefined,
        name: item.name,
        code: item.code,
      });
    });

    try {
      const response = await sdk.upsertCatalogs({
        upsertCatalogsInput: upsertCatalogs,
      });

      if (response?.upsertCatalogs) {
        return response.upsertCatalogs;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new CatalogService();

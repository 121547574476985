import { GuidInput, Option, OptionItem, OptionType } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * 商品オプションや用途オプションに関する機能を提供します.
 */
class OptionService {
  /**
   * 初期状態のオプション
   */
  public get defaultOption(): Option {
    return {
      guid: "",
      name: "",
      expressionName: null,
      memo: "",
      displayOrder: 0,
      items: [],
      purposes: [],
      optionItems: [],
      optionType: null,
      createdAt: "",
      updatedAt: "",
    };
  }

  /**
   * すべてのオプションを取得して返します.
   * @returns すべてのオプション
   */
  public async allOptions() {
    const response = await sdk.options();
    if (response?.options) {
      return response.options as Option[];
    }
    return [] as Option[];
  }

  /**
   * 指定したGUIDに一致するオプションを取得して返します.
   * @param guid オプションのGUID
   * @returns オプション
   */
  public async getOption(guid: string) {
    const response = await sdk.option({ optionGuid: guid });
    return response.option as Option;
  }

  /**
   * オプションを登録します.
   * オプションプロパティとの関連も同時に登録されます.
   * @param input オプションの登録情報
   * @returns 登録完了後のオプション
   */
  public async createOption(input: Option) {
    const optionItems: GuidInput[] = [];
    if (input.optionItems) {
      input.optionItems.forEach((item) => {
        if (item) optionItems.push({ guid: item.guid });
      });
    }
    try {
      const response = await sdk.createOption({
        createOptionInput: {
          name: input.name,
          expressionName: input.expressionName
            ? input.expressionName
            : undefined,
          memo: input.memo,
          displayOrder: input.displayOrder,
          optionType: input.optionType,
          optionItems: optionItems,
        },
      });

      if (response?.createOption) {
        return response.createOption;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * オプションを更新します.
   * オプションアイテムとの関連も同時に更新されます.
   * @param input オプションの更新情報
   * @returns 更新完了後のオプション
   */
  public async updateOption(input: Option) {
    const optionItems: GuidInput[] = [];
    if (input.optionItems) {
      input.optionItems.forEach((item) => {
        if (item) optionItems.push({ guid: item.guid });
      });
    }
    try {
      const response = await sdk.updateOption({
        updateOptionInput: {
          guid: input.guid,
          name: input.name,
          expressionName: input.expressionName
            ? input.expressionName
            : undefined,
          memo: input.memo,
          displayOrder: input.displayOrder,
          optionType: input.optionType,
          optionItems: optionItems,
        },
      });

      if (response?.updateOption) {
        return response.updateOption;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * オプションを削除します.
   * @param input オプションの削除情報
   * @returns 削除したオプション
   */
  public async deleteOption(input: Option) {
    try {
      const response = await sdk.deleteOption({
        deleteOptionInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteOption) {
        return response.deleteOption;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 初期状態のオプションアイテム
   */
  public get defaultOptionItem(): OptionItem {
    return {
      guid: "",
      name: "",
      options: [],
      properties: [],
      displayOrder: 0,
      imageUrl: "",
      optionType: null,
      oldPackingId: null,
      createdAt: "",
      updatedAt: "",
    };
  }

  /**
   * すべてのオプションアイテムを取得して返します.
   * @returns すべてのオプションアイテム
   */
  public async allOptionItems() {
    const response = await sdk.optionItems();
    if (response?.optionItems) {
      return response.optionItems as OptionItem[];
    }
    return [] as OptionItem[];
  }

  /**
   * 指定したオプション種別に一致するオプションアイテムを取得して返します.
   * @param type オプション種別
   * @returns オプションアイテムの配列
   */
  public async searchOptionItemsByOptionType(type: OptionType) {
    const response = await sdk.searchOptionItemsByOptionType({
      searchOptionItemsByOptionTypeOptionType: type,
    });
    if (response?.searchOptionItemsByOptionType) {
      return response.searchOptionItemsByOptionType as OptionItem[];
    }
    return [] as OptionItem[];
  }

  /**
   * 指定したGUIDに一致するオプションアイテムを取得して返します.
   * @param guid オプションアイテムのGUID
   * @returns オプションアイテム
   */
  public async getOptionItem(guid: string) {
    const response = await sdk.optionItem({ optionItemGuid: guid });
    return response.optionItem as OptionItem;
  }

  /**
   * オプションアイテムを登録します.
   * プロパティとの関連も同時に登録されます.
   * @param input オプションアイテムの登録情報
   * @returns 登録完了後のオプションアイテム
   */
  public async createOptionItem(input: OptionItem) {
    const properties: GuidInput[] = [];
    if (input.properties) {
      input.properties.forEach((item) => {
        if (item) properties.push({ guid: item.guid });
      });
    }
    try {
      const response = await sdk.createOptionItem({
        createOptionItemInput: {
          name: input.name,
          optionType: input.optionType,
          imageUrl: input.imageUrl,
          displayOrder: input.displayOrder,
          oldPackingId: input.oldPackingId,
          properties: properties,
        },
      });

      if (response?.createOptionItem) {
        return response.createOptionItem;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * オプションアイテムを更新します.
   * プロパティとの関連も同時に更新されます.
   * @param input オプションアイテムの更新情報
   * @returns 更新完了後のオプションアイテム
   */
  public async updateOptionItem(input: OptionItem) {
    const properties: GuidInput[] = [];
    if (input.properties) {
      input.properties.forEach((item) => {
        if (item) properties.push({ guid: item.guid });
      });
    }
    try {
      const response = await sdk.updateOptionItem({
        updateOptionItemInput: {
          guid: input.guid,
          name: input.name,
          optionType: input.optionType,
          imageUrl: input.imageUrl,
          displayOrder: input.displayOrder,
          oldPackingId: input.oldPackingId,
          properties: properties,
        },
      });

      if (response?.updateOptionItem) {
        return response.updateOptionItem;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * オプションアイテムを削除します.
   * @param input オプションアイテムの削除情報
   * @returns 削除したオプションアイテム
   */
  public async deleteOptionItem(input: OptionItem) {
    try {
      const response = await sdk.deleteOptionItem({
        deleteOptionItemInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteOptionItem) {
        return response.deleteOptionItem;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new OptionService();

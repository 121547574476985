import { GraphQLResponse } from "graphql-request/dist/types";

/**
 * 指定したオブジェクトをGraphQLResponseにして返します。
 * 変換できなかった場合はnull
 * @param obj 
 * @returns 
 */
export const toGraphQLResponse = (obj: any): GraphQLResponse | null => {
  if (obj.response) {
    const response = obj.response as GraphQLResponse;
    return response;
  }
  return null;
}

import { GuidInput, Purpose } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";
/**
 * 用途に関する機能を提供します.
 */
class PurposeService {
  /**
   * 初期状態の Purpose
   */
  public get defaultPurpose(): Purpose {
    return {
      guid: "",
      categoryName: "",
      slugName: "",
      name: "",
      displayOrder: 0,
      updatedAt: "",
      createdAt: "",
      isAvailable: true,
      freeShippingLine: null,
      purposeNo: null,
      items: [],
      options: [],
    } as Purpose;
  }

  /**
   * すべての用途を取得して返します.
   * @returns すべての用途
   */
  public async allPurposes() {
    const response = await sdk.purposes();
    if (response?.purposes) {
      return response.purposes as Purpose[];
    }
    return [] as Purpose[];
  }

  /**
   * 指定したGUIDに一致する用途を取得して返します.
   * @param guid 用途のGUID
   * @returns 用途
   */
  public async getPurpose(guid: string) {
    const response = await sdk.purpose({ purposeKey: { guid: guid } });
    return response.purpose as Purpose;
  }

  /**
   * 用途を登録します.
   * 用途プロパティも同時に登録されます.
   * @param input 用途の登録情報
   * @returns 登録完了後の用途
   */
  public async createPurpose(input: Purpose) {
    const options: GuidInput[] = [];
    if (input.options) {
      input.options.forEach((item) => {
        if (item) options.push({ guid: item.guid });
      });
    }
    if (input.purposeNo?.toString() == "") {
      input.purposeNo = null;
    }
    try {
      const response = await sdk.createPurpose({
        createPurposeInput: {
          name: input.name,
          slugName: input.slugName,
          categoryName: input.categoryName,
          displayOrder: input.displayOrder,
          isAvailable: input.isAvailable,
          freeShippingLine: input.freeShippingLine,
          purposeNo: input.purposeNo,
          options: options ? options : undefined,
        },
      });

      if (response?.createPurpose) {
        return response.createPurpose;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 用途を更新します.
   * 用途プロパティも同時に更新（削除および登録）されます.
   * @param input 用途の更新情報
   * @returns 更新完了後の用途
   */
  public async updatePurpose(input: Purpose) {
    const options: GuidInput[] = [];
    if (input.options) {
      input.options.forEach((item) => {
        if (item) options.push({ guid: item.guid });
      });
    }
    if (input.purposeNo?.toString() == "") {
      input.purposeNo = null;
    }
    try {
      if (!input.guid) {
        throw new Error("guid is required.");
      }
      const response = await sdk.updatePurpose({
        updatePurposeInput: {
          guid: input.guid,
          name: input.name,
          slugName: input.slugName,
          categoryName: input.categoryName,
          displayOrder: input.displayOrder,
          isAvailable: input.isAvailable,
          freeShippingLine: input.freeShippingLine,
          purposeNo: input.purposeNo,
          options: options ? options : undefined,
        },
      });

      if (response?.updatePurpose) {
        return response.updatePurpose;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 指定した用途を削除します.
   * @param 用途 用途
   * @returns 削除が完了した用途
   */
  public async deletePurpose(input: Purpose) {
    try {
      if (!input.guid) {
        throw new Error("guid is required.");
      }
      const response = await sdk.deletePurpose({
        deletePurposeInput: { guid: input.guid },
      });

      if (response?.deletePurpose) {
        return response.deletePurpose;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new PurposeService();

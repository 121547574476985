
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MixinSearchTimer extends Vue {
  //---------------------------
  // data
  //---------------------------
  searchTimer:any = null;

  /**
   * 
   * @param searchCallback 
   */
  searchTimeOut(searchCallback: () => void) {  
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
      this.searchTimer = null;
    }
    this.searchTimer = setTimeout(() => {
      // do your callback
      if (searchCallback) {
        searchCallback();
      }
    }, 800);
  }
}


import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { numeric, helpers, integer, required } from "vuelidate/lib/validators";
import moment from "moment";


@Component
export default class MixinItemEditDialogValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //名称
      name: { required },
      //WEB品番
      webCode: { required },
      //大進品番
      daishinCode: { required },
      //価格
      price: { required, numeric },
      //送料
      postage: { required, numeric },
      //表示順
      displayOrder: { required, integer },
      //最低ロット数
      minimumOrderQuantity: { required, numeric },
      //在庫数
      stockQuantity: { integer },
      //発送までに必要な日数
      sendday: { numeric },
      //特別価格
      specialPrice: {
        required,
        numeric,
      },
      //会員特別価格
      specialMemberPrice: {
        required,
        numeric,
      },
      //アフィリエイト価格
      affiliatePrice: {
        required,
        numeric,
      },
    },
    publicationDate: {
      validDate: (value: string, parentVm: Vue): boolean => {
        const targetDate = helpers.ref("publicationEndDate", this, parentVm);
        return moment(value).isBefore(moment(targetDate));
      },
    },
    publicationEndDate: {
      validDate: (value: string, parentVm: Vue): boolean => {
        const targetDate = helpers.ref("publicationDate", this, parentVm);
        return moment(value).isAfter(moment(targetDate));
      },
    },
  };
  //---------------------------
  // methods
  //---------------------------
  /**
   * 名称の入力エラーメッセージを返します.
   */
  get nameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.name?.$dirty) return errors;
    !this.$v.item.name?.required && errors.push("名称を入力してください.");
    return errors;
  }
  /**
   * WEB品番の入力エラーメッセージを返します.
   */
  get webCodeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.webCode?.$dirty) return errors;
    !this.$v.item.webCode?.required && errors.push("WEB品番を入力してください.");
    return errors;
  }
  /**
   * 大進品番の入力エラーメッセージを返します.
   */
  get daishinCodeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.daishinCode?.$dirty) return errors;
    !this.$v.item.daishinCode?.required &&
      errors.push("大進品番を入力してください.");
    return errors;
  }
  /**
   * 表示順の入力エラーメッセージを返します.
   */
  get displayOrderErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.displayOrder?.$dirty) return errors;
    !this.$v.item.displayOrder?.required &&
      errors.push("表示順を入力してください.");
    !this.$v.item.displayOrder?.integer &&
      errors.push("数値を入力してください.");
    return errors;
  }
  /**
   * 価格の入力エラーメッセージを返します.
   */
  get priceErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.price?.$dirty) return errors;
    !this.$v.item.price?.required && errors.push("価格を入力してください.");
    !this.$v.item.price?.numeric && errors.push("数値を入力してください.");
    return errors;
  }
  /**
   * 送料の入力エラーメッセージを返します.
   */
  get postageErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.postage?.$dirty) return errors;
    !this.$v.item.postage?.required && errors.push("送料を入力してください.");
    !this.$v.item.postage?.numeric && errors.push("数値を入力してください.");
    return errors;
  }
  /**
   * 発送までに必要な日数の入力エラーメッセージを返します.
   */
  get senddayErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.sendday?.$dirty) return errors;
    // !this.$v.item.sendday?.required && errors.push("発送までに必要な日数を入力してください.");
    !this.$v.item.sendday?.numeric && errors.push("数値を入力してください.");
    return errors;
  }
  /**
   * 最低ロット数の入力エラーメッセージを返します.
   */
  get minimumOrderQuantityErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.minimumOrderQuantity?.$dirty) return errors;
    !this.$v.item.minimumOrderQuantity?.required && errors.push("最低ロット数を入力してください.");
    !this.$v.item.minimumOrderQuantity?.numeric &&
      errors.push("数値を入力してください.");
    return errors;
  }
  /**
   * 在庫数の入力エラーメッセージを返します.
   */
  get stockQuantityErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.stockQuantity?.$dirty) return errors;
    // !this.$v.item.stockQuantity?.required && errors.push("在庫数を入力してください.");
    !this.$v.item.stockQuantity?.integer &&
      errors.push("数値を入力してください.");
    return errors;
  }
  /**
   * 公開開始日の入力エラーメッセージを返します.
   */
  get publicationDateErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.publicationDate.$dirty) return errors;
    !this.$v.publicationDate.validDate &&
      errors.push("公開終了日より前の日時を指定してください.");
    return errors;
  }
  /**
   * 公開終了日の入力エラーメッセージを返します.
   */
  get publicationEndDateErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.publicationEndDate.$dirty) return errors;
    !this.$v.publicationEndDate.validDate &&
      errors.push("公開開始日より後の日時を指定してください.");
    return errors;
  }

  /**
   * 特別価格の入力エラーメッセージを返します.
   */
  get specialPriceErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.specialPrice?.$dirty) return errors;
    !this.$v.item.specialPrice?.required &&
      errors.push("特別価格を入力してください.");
    !this.$v.item.specialPrice?.numeric &&
      errors.push("数値を入力してください.");
    return errors;
  }

  /**
   * 会員特別価格の入力エラーメッセージを返します.
   */
  get specialMemberPriceErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.specialMemberPrice?.$dirty) return errors;
    !this.$v.item.specialMemberPrice?.required &&
      errors.push("会員特別価格を入力してください.");
    !this.$v.item.specialMemberPrice?.numeric &&
      errors.push("数値を入力してください.");
    return errors;
  }

  /**
   * アフィリエイト価格の入力エラーメッセージを返します.
   */
  get affiliatePriceErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.affiliatePrice?.$dirty) return errors;
    !this.$v.item.affiliatePrice?.required &&
      errors.push("アフィリエイト価格を入力してください.");
    !this.$v.item.affiliatePrice?.numeric &&
      errors.push("数値を入力してください.");
    return errors;
  }
}

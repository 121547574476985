






























import { Component, ModelSync, Ref, Vue } from "vue-property-decorator";

@Component
export default class ImagePicker extends Vue {
  @ModelSync("imageUrl", "updateImageUrl", { type: String, required: false })
  readonly imageUrlValue!: string;
  @Ref() readonly fileInput!: HTMLInputElement;
  
  //---------------------------
  // data
  //---------------------------
  seletedFile = null;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async onImagePicked(file: File): Promise<void> {
    if (file !== undefined && file !== null) {
      try {
        const content = await this.readAsDataAsync(file);
        this.$emit("updateImageUrl", content);
      } catch (e) {
        console.log(e);
      }
    } else {
      if (!file) this.$emit("updateImageUrl", "");
    }
  }
  readAsDataAsync(file: File): Promise<string> {
    return new Promise<string>((resolve, reject): void => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        fileReader.result ? resolve(fileReader.result.toString()) : reject;
      };
      fileReader.onerror = reject;
      fileReader.readAsDataURL(file);
    });
  }
  //画像削除
  public removeImage(): void {
    this.$emit("updateImageUrl", "");
    this.seletedFile = null;
  }
  public reset(): void {
    this.removeImage();

  }
}
